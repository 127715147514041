import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent} from './components/main/main.component';
import {AboutusComponent} from './components/aboutus/aboutus.component';
import {PracticeAreasComponent} from './components/practice-areas/practice-areas.component';
// import {PracticeSingleComponent} from './components/practice-areas/practice-single/practice-single.component';
import {AttorneysComponent} from './components/attorneys/attorneys.component';
import {BlogComponent} from './components/blog/blog.component';
import {BlogSingleComponent} from './components/blog/blog-single/blog-single.component';
import {ContactComponent} from './components/contact/contact.component';
import { FamilyLawComponent } from './components/practice-areas/single/family-law/family-law.component';
import { CorporateCommercialLawComponent } from './components/practice-areas/single/corporate-commercial-law/corporate-commercial-law.component';
import { LandRevenueRealEstateLawsComponent } from './components/practice-areas/single/land-revenue-real-estate-laws/land-revenue-real-estate-laws.component';
import { CriminalLawComponent } from './components/practice-areas/single/criminal-law/criminal-law.component';
import { IntellectualPropertyLawsComponent } from './components/practice-areas/single/intellectual-property-laws/intellectual-property-laws.component';
import { EmploymentLabourLawComponent } from './components/practice-areas/single/employment-labour-law/employment-labour-law.component';
import { MotorAccidentClaimsComponent } from './components/practice-areas/single/motor-accident-claims/motor-accident-claims.component';
import { BankingFinancialLawComponent } from './components/practice-areas/single/banking-financial-law/banking-financial-law.component';
import { NDPSComponent } from './components/practice-areas/single/ndps/ndps.component';
import { SexualOffensesComponent } from './components/practice-areas/single/sexual-offenses/sexual-offenses.component';
import { CivilLawComponent } from './components/practice-areas/single/civil-law/civil-law.component';
import { InsolvencyBankruptcyLawsComponent } from './components/practice-areas/single/insolvency-bankruptcy-laws/insolvency-bankruptcy-laws.component';
import { CivilRightsLawComponent } from './components/practice-areas/single/civil-rights-law/civil-rights-law.component';
import { ChequeBounceMattersComponent } from './components/practice-areas/single/cheque-bounce-matters/cheque-bounce-matters.component';
import { DisputeLawComponent } from './components/practice-areas/single/dispute-law/dispute-law.component';
import { DivorceComponent } from './components/practice-areas/single/divorce/divorce.component';
import { MEDICOLegalComponent } from './components/practice-areas/single/medico-legal/medico-legal.component';
import { POSHComponent } from './components/practice-areas/single/posh/posh.component';
import { Article1Component } from './components/blog/blog-single/article1/article1.component';
import { Article2Component } from './components/blog/blog-single/article2/article2.component';
import { FoundersComponent } from './components/aboutus/founders/founders.component';
import { VisionComponent } from './components/aboutus/vision/vision.component';
import { OfficesComponent } from './components/aboutus/offices/offices.component';

const routes: Routes = [
  {path: '', component: MainComponent},
  {path: 'about-us', component: AboutusComponent },
  {path: 'founders', component: FoundersComponent },
  {path: 'vision', component: VisionComponent },
  {path: 'offices', component: OfficesComponent },
  {path: 'practice-areas', component: PracticeAreasComponent},
  // {path: 'practice-areas/single', component: PracticeSingleComponent},
  {path: 'practice-areas/family-law', component: FamilyLawComponent},
  {path: 'practice-areas/corporate-commercial-law', component: CorporateCommercialLawComponent},
  {path: 'practice-areas/land-revenue-realEstate-laws', component: LandRevenueRealEstateLawsComponent},
  {path: 'practice-areas/criminal-law', component: CriminalLawComponent},
  {path: 'practice-areas/intellectual-property-law', component: IntellectualPropertyLawsComponent},
  {path: 'practice-areas/employment-labour-law', component: EmploymentLabourLawComponent},
  {path: 'practice-areas/motor-accident-claims-law', component: MotorAccidentClaimsComponent},
  {path: 'practice-areas/banking-financial-law', component: BankingFinancialLawComponent},
  {path: 'practice-areas/NDPS-law', component: NDPSComponent},
  {path: 'practice-areas/sexual-offenses-law', component:SexualOffensesComponent },
  {path: 'practice-areas/civil-law', component:CivilLawComponent },
  {path: 'practice-areas/insolvency-bankruptcy-law', component:InsolvencyBankruptcyLawsComponent },
  {path: 'practice-areas/civil-rights-law', component:CivilRightsLawComponent },
  {path: 'practice-areas/cheque-bounce-matters-law', component:ChequeBounceMattersComponent },
  {path: 'practice-areas/dispute-law', component:DisputeLawComponent },
  {path: 'practice-areas/divorce-law', component:DivorceComponent },
  {path: 'practice-areas/MEDICO-legal-law', component:MEDICOLegalComponent },
  {path: 'practice-areas/POSH-law', component:POSHComponent },
  {path: 'attorneys', component: AttorneysComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'blog/single', component: BlogSingleComponent},
  {path: 'blog/1', component: Article1Component},
  {path: 'blog/2', component: Article2Component},
  {path: 'contact', component: ContactComponent},
  {path: '', component: MainComponent, pathMatch:  'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
