import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dispute-law',
  templateUrl: './dispute-law.component.html',
  styleUrls: ['./dispute-law.component.css']
})
export class DisputeLawComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
