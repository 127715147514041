import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-civil-rights-law',
  templateUrl: './civil-rights-law.component.html',
  styleUrls: ['./civil-rights-law.component.css']
})
export class CivilRightsLawComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
