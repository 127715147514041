import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-law',
  templateUrl: './family-law.component.html',
  styleUrls: ['./family-law.component.css']
})
export class FamilyLawComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
