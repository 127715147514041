import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-commercial-law',
  templateUrl: './corporate-commercial-law.component.html',
  styleUrls: ['./corporate-commercial-law.component.css']
})
export class CorporateCommercialLawComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
