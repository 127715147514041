import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-motor-accident-claims',
  templateUrl: './motor-accident-claims.component.html',
  styleUrls: ['./motor-accident-claims.component.css']
})
export class MotorAccidentClaimsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
