import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employment-labour-law',
  templateUrl: './employment-labour-law.component.html',
  styleUrls: ['./employment-labour-law.component.css']
})
export class EmploymentLabourLawComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
