import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailService } from 'src/app/services/email.service';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  data = {
    name: "",
    from: "",
    subject: "",
    emailbody: ""
  };

  flag=false;

  constructor(private email: EmailService,
    private snak: MatSnackBar,
    ) { }

  ngOnInit() {
  }

  submit() {

    if (this.data.name == '' ||this.data.from == '' || this.data.subject == '' || this.data.emailbody  == '') {
        this.snak.open("fields can not be empty !!", "OK");
        return;
      }
this.flag=true;
      this.email.sendEmail(this.data).subscribe(
    response=>{
      console.log(response);   
      this.flag=false; 
      this.snak.open("Send Success ","OK")  
    },
    error=>{
      console.log(error); 
      this.flag=false;    
      this.snak.open("ERROR!! ","OK")   
    }
  )  

  }

  // async send() {
  //   emailjs.init('private key');
  //   let response = await emailjs.send('', '', {
  //     from_name: this.form.value.from_name,
  //     to_name: this.form.value.to_name,
  //     from_email: this.form.value.from_email,
  //     subject: this.form.value.subject,
  //     message: this.form.value.message,
  //   });

  //   alert('Message has been sent.');
  //   this.form.reset();
  // console.log("try to submit form");
  // console.log("DATA ",this.data);

  // if (this.form. == '' ||this.data.email == '' || this.data.subject == '' || this.data.text  == '') {
  //   this.snak.open("fields can not be empty !!", "OK");
  //   return;
  // }

  //   this.flag=true;
  // this.email.sendEmail(this.data).subscribe(
  //   response=>{
  //     console.log(response);   
  //     this.flag=false; 
  //     this.snak.open("Send Success ","OK")  
  //   },
  //   error=>{
  //     console.log(error); 
  //     this.flag=false;    
  //     this.snak.open("ERROR!! ","OK")   
  //   }
  // )  

  // }

}