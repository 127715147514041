import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(public dialog: MatDialog) { }


  ngOnInit() {
    // this.openDialog()
    

    const carousel = () => {
      $('.carousel-testimony').owlCarousel({
        navigation: true,
        center: true,
        loop: true,
        items: 1,
        margin: 30,
        stagePadding: 0,
        nav: false,
        navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 2
          },
          1000: {
            items: 3
          }
        }
      });
    };
    carousel();
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(disclaimerDialogbox);
  // }
}

// @Component({
//   selector: 'disclaimerDialogbox',
//   templateUrl: 'disclaimerDialogbox.html',
// })
// export class disclaimerDialogbox {}
