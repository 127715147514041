import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insolvency-bankruptcy-laws',
  templateUrl: './insolvency-bankruptcy-laws.component.html',
  styleUrls: ['./insolvency-bankruptcy-laws.component.css']
})
export class InsolvencyBankruptcyLawsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
