import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intellectual-property-laws',
  templateUrl: './intellectual-property-laws.component.html',
  styleUrls: ['./intellectual-property-laws.component.css']
})
export class IntellectualPropertyLawsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
