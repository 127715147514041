import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'QUESTLEX & PARTNERS-lawyers-firm';

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.openDialog()

  }
  
  //   onActivate(event: any) {
//     let scrollToTop = window.setInterval(() => {
//         let pos = window.pageYOffset;
//         if (pos > 0) {
//             window.scrollTo(0, pos - 20); // how far to scroll on each step
//         } else {
//             window.clearInterval(scrollToTop);
//         }
//     }, 16);

onActivate(event: any) {
  // window.scroll(0,0);

  window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
   });
  }
  openDialog() {
    const dialogRef = this.dialog.open(disclaimerDialogbox,{
      maxWidth: '80vw',
      maxHeight: '150vw',
    });
  }
}

@Component({
  selector: 'disclaimerDialogbox',
  templateUrl: 'disclaimerDialogbox.html',
})
export class disclaimerDialogbox {}