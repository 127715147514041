import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banking-financial-law',
  templateUrl: './banking-financial-law.component.html',
  styleUrls: ['./banking-financial-law.component.css']
})
export class BankingFinancialLawComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
