import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent, disclaimerDialogbox } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { PracticeAreasComponent } from './components/practice-areas/practice-areas.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { AttorneysComponent } from './components/attorneys/attorneys.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogSingleComponent } from './components/blog/blog-single/blog-single.component';
import { PracticeSingleComponent } from './components/practice-areas/practice-single/practice-single.component';
import { ContactComponent } from './components/contact/contact.component';
import {AppRoutingModule} from './app-routing.module';
import { CorporateCommercialLawComponent } from './components/practice-areas/single/corporate-commercial-law/corporate-commercial-law.component';
import { LandRevenueRealEstateLawsComponent } from './components/practice-areas/single/land-revenue-real-estate-laws/land-revenue-real-estate-laws.component';
import { CriminalLawComponent } from './components/practice-areas/single/criminal-law/criminal-law.component';
import { IntellectualPropertyLawsComponent } from './components/practice-areas/single/intellectual-property-laws/intellectual-property-laws.component';
import { EmploymentLabourLawComponent } from './components/practice-areas/single/employment-labour-law/employment-labour-law.component';
import { MotorAccidentClaimsComponent } from './components/practice-areas/single/motor-accident-claims/motor-accident-claims.component';
import { BankingFinancialLawComponent } from './components/practice-areas/single/banking-financial-law/banking-financial-law.component';
import { NDPSComponent } from './components/practice-areas/single/ndps/ndps.component';
import { SexualOffensesComponent } from './components/practice-areas/single/sexual-offenses/sexual-offenses.component';
import { CivilLawComponent } from './components/practice-areas/single/civil-law/civil-law.component';
import { InsolvencyBankruptcyLawsComponent } from './components/practice-areas/single/insolvency-bankruptcy-laws/insolvency-bankruptcy-laws.component';
import { CivilRightsLawComponent } from './components/practice-areas/single/civil-rights-law/civil-rights-law.component';
import { DisputeLawComponent } from './components/practice-areas/single/dispute-law/dispute-law.component';
import { DivorceComponent } from './components/practice-areas/single/divorce/divorce.component';
import { MEDICOLegalComponent } from './components/practice-areas/single/medico-legal/medico-legal.component';
import { POSHComponent } from './components/practice-areas/single/posh/posh.component';
import { FamilyLawComponent } from './components/practice-areas/single/family-law/family-law.component';
import { ChequeBounceMattersComponent } from './components/practice-areas/single/cheque-bounce-matters/cheque-bounce-matters.component';
import { Article2Component } from './components/blog/blog-single/article2/article2.component';
import { Article1Component } from './components/blog/blog-single/article1/article1.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './Material/material';
import { HttpClientModule } from '@angular/common/http';
import { MainComponent } from './components/main/main.component';
import { EmailService } from './services/email.service';
import { ReactiveFormsModule } from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FoundersComponent } from './components/aboutus/founders/founders.component';
import { VisionComponent } from './components/aboutus/vision/vision.component';
import { OfficesComponent } from './components/aboutus/offices/offices.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    AboutusComponent,
    PracticeAreasComponent,
    NewsletterComponent,
    AttorneysComponent,
    BlogComponent,
    BlogSingleComponent,
    PracticeSingleComponent,
    ContactComponent,
    CorporateCommercialLawComponent,
    LandRevenueRealEstateLawsComponent,
    CriminalLawComponent,
    IntellectualPropertyLawsComponent,
    EmploymentLabourLawComponent,
    MotorAccidentClaimsComponent,
    BankingFinancialLawComponent,
    NDPSComponent,
    SexualOffensesComponent,
    CivilLawComponent,
    InsolvencyBankruptcyLawsComponent,
    CivilRightsLawComponent,
    DisputeLawComponent,
    DivorceComponent,
    MEDICOLegalComponent,
    POSHComponent,
    FamilyLawComponent,
    ChequeBounceMattersComponent,
    Article2Component,
    Article1Component,
    disclaimerDialogbox,
    FoundersComponent,
    VisionComponent,
    OfficesComponent
  ],
  entryComponents: [
    disclaimerDialogbox
  ],

  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,  
    ReactiveFormsModule ,
    MatProgressSpinnerModule
    
  ],
  providers: [EmailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
